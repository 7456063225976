import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { IoRemove } from "react-icons/io5"
import "../styles/contact.css"

import "../styles/main.css"
const Contact = () => {
  return (
    <section className="contact__section">
      <article className="contact__text">
        <div className="contact__text--header">
          <div className="underline"></div>
          <h1>
            Let's <span className="contact__text--span">Talk</span>
          </h1>
          <p>
            Reach out to say hi or if you would like to start a new project or
            collaboration.
          </p>
        </div>
        <div className="contact__img">
          <StaticImage
            src="../assets/analytics.svg"
            alt="analytics illustration"
            className="contact__img--svg"
            placeholder="blurred"
          />
        </div>
      </article>
      <div className="contact-form">
        <form action="https://formspree.io/f/xoqryqov" method="POST">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              name="name"
              placeholder="your name"
              className="form-input"
            />

            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="email@email.com"
              className="form-input"
            />

            <label htmlFor="message">How can I help you?</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              placeholder="enter your message here"
              className="form-input"
            ></textarea>
          </div>
          <button
            type="submit"
            className="btn btn--secondary"
            aria-label="send message button"
          >
            <span className="btn__text">Send message</span>
            <span className="btn__icon--minus">
              <IoRemove></IoRemove>
            </span>
          </button>
        </form>
      </div>
    </section>
  )
}

export default Contact
