import React from "react"
import Layout from "../components/Layout"
import Contact from "../components/Contact"

const ContactPage = () => {
  return (
    <Layout title="Contact | Rayane Silva" description="Contact form">
      <section className="contact-page">
        <Contact />
      </section>
    </Layout>
  )
}

export default ContactPage
